.error-div {
	display: none;
	position: fixed;
	top: 17px;
	width: 25%;
	z-index: 100;
	color: #721c24;
	background-color: #ff7272;
	border-color: #ff7272;
	height: 80px;
	left: 38%;
	padding: 11px;
	border-top-left-radius: 15px;
	border-bottom-right-radius: 15px;
	img {
		cursor: pointer;
		width: 21px;
		position: absolute;
		right: -6px;
		top: -20px;
		background: #5d5d5d;
		border-radius: 50%;
	}
	.row {
		align-self: center;
		height: 100%;
	}
}

#errorTxt {
	color: #fff;
	margin-left: 20px;
}

.success-div {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	color: #155724;
	background-color: #d4edda;
	border-color: #d4edda;
	height: 45px;
	img {
		cursor: pointer;
		width: 45px;
		position: absolute;
		right: 9px;
	}
	.row {
		align-self: center;
		height: 100%;
	}
}
