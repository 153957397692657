.TableComponentContainer {
	.ant-table-wrapper {
		box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000033;
		border-radius: 10px;
	}
	.ant-table-pagination {
		padding: 0px 10px 10px 10px;
	}
	.headerContainer {
		.btnContainer {
			text-align: end;
			.dropdown {
				display: inline-block;
			}
			.buttonTheme {
				margin-bottom: 0 !important;
			}
		}
	}
	.buttonTheme {
		// border: 2px solid transparent;
		// border-image: linear-gradient(to right, #6e2c8a, #e11f2f);
		// border-image-slice: 1;
		// background: transparent;
		// color: black;
	}
	.IconContainer {
		.fa-trash-o {
			color: #dd3232;
			margin-right: 15px;
			cursor: pointer;
			font-size: 17px;
		}
		.fa-edit,
		.fa-info-circle,
		.fa-eye,
		.fa-redo {
			color: #817e83;
			cursor: pointer;
			font-size: 17px;
			margin-right: 15px;
		}
	}
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
	text-align: center;
}

.ant-table table {
	// cursor: pointer;
}

.ExportDropDown {
	display: inline-flex !important;
	align-items: center;
	background: linear-gradient(-135deg, #ed1d24 0%, #672c91 100%);
	border: #732c88 !important;
	// padding: 8px !important;
	margin-bottom: 0 !important;
	p {
		margin-bottom: 0;
		margin-left: 5px;
		margin-right: 5px;
	}
}
.csvContainer {
	padding: 10px;
	cursor: pointer;
	text-align: start;

	&:hover {
		background-color: aliceblue;
	}
}

.senderIcon {
	width: 25px;
	cursor: pointer;
	margin-left: 9px;
}
