.InputWithTextContainer {
	
	.InputContainer{
		position: relative ;
	}
	.label {
		// color: black ;
	}
	.ErrorBorder {
		border-color: #ea6464;
	}
	.eyeIcon {
		position: absolute;
		top: 16px;
		right: 10px;
		cursor: pointer;
		color: #912a73;
	}
	.errorLabel {
		color: #ea6464 ;
		margin-left: 10px ;
		font-size: 12px ;
        width: 100%;
        text-align: initial;
	}
}
