.CustomeDropDown {
	margin-top: 4px;
	.UncontrolledDropdownContainer {
		border: 1px solid #ced4d9;
		border-radius: 5px;
		background: #f4f7fa;

		.noDataLabel {
			margin: auto;
			display: table;
			color: #7a7979;
		}
		a {
			color: #000;
		}
		.dropdown-menu .dropdown-item.active,
		.dropdown-menu .dropdown-item:active {
			background-color: #d4d5d8;
		}

		.dropdown-menu .dropdown-item,
		.bootstrap-select .dropdown-menu.inner li a {
			font-size: 16px;
			cursor: pointer;
		}
		i {
			right: 18px;
			position: absolute;
			top: 13px;
		}
		.dropdown-menu {
			width: 100%;
			height: 200px;
			overflow: hidden;
			overflow-y: scroll;
		}
		.dropdown-toggle::after {
			display: none !important;
		}
		.dropdown-toggle
		{
			white-space: normal;
		}
	}

	.errorDropDown {
		border-color: red;
	}
	.dropDownTitle {
		margin-left: 7px;
	}
}
